import React, { useState } from 'react';
import "./FileUpload.css"

import useLoadingLocal from '../loading/hook/LoadingLocal';
import LoadingKompass from '../loading/kompass/LoadingKompass';

import RequestHelper from '../requesthelper/RequestHelper';

import { toastEventEmitter } from '../toast/ToastEmitter';

const FileUpload: React.FC = () => {
  const [isLoading, startLoading, stopLoading] = useLoadingLocal();
  const [files, setFiles] = useState<File[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      // Konvertiere FileList in ein Array und füge es zum State hinzu
      setFiles([...files, ...Array.from(event.target.files)]);
    }
  };

  const handleRemoveFile = (index: number) => {
    // Entferne eine Datei aus der Liste basierend auf ihrem Index
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    if (files.length === 0) {
      toastEventEmitter.addToast("Bitte wählen sie mindestens eine Datei aus", 3000, 'error')
      return;
    }

    // Füge alle Dateien zur FormData hinzu
    files.forEach(file => {
      formData.append('files', file); // 'files' ist der Schlüssel, den du im Flask-Backend verwenden wirst
    });

    try {
      startLoading();

      const response = await RequestHelper.post('/uploadFiles', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status == 200) {
        console.log('Upload successful:', response);
        stopLoading();
        toastEventEmitter.addToast("Upload erfolgreich", 3000, 'success')
      }
      else {
        console.error('Upload failed:', response.statusText);
        stopLoading();
        toastEventEmitter.addToast("Fehler beim Upload", 3000, 'error')
      }
    } catch (error) {
      console.error('Error during upload:', error);
      stopLoading();
      toastEventEmitter.addToast("Fehler beim Upload", 3000, 'error')
    }
  };

  return (
    <div>
      <LoadingKompass isLoading={isLoading} />

      <div className="fileupload-modal">
        <div className="fileupload-content">
          <p className="fileupload-message">
            Select a file to upload from your computer or device.
          </p>

          <div className="fileupload-actions">
            <label
              htmlFor="fileupload-file"
              className="fileupload-button fileupload-btn"
            >
              Choose File
              <input
                type="file"
                multiple
                accept="image/*,.pdf,.docx,.txt"
                onChange={handleFileChange}
                id="fileupload-file"
                hidden
              />
            </label>
          </div>

          <div className="fileupload-result">
            {files.map((file, index) => (
              <div key={index} className="fileupload-file-uploaded">
                {file.name}
                <button
                  className="remove-file-button"
                  onClick={() => handleRemoveFile(index)}
                >
                  Entfernen
                </button>
              </div>
            ))}
          </div>
        </div>

         {/* Wrapper für zentrierten Button */}
        <div className="fileupload-submit-container">
          <button onClick={handleSubmit} className="fileupload-submit-button">
            Hochladen
          </button>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
