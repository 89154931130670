import { CodeResponse } from "@react-oauth/google";

import RequestHelper from "../requesthelper/RequestHelper";

export async function call_google_login(codeResponse: CodeResponse): Promise<any> 
{
    try 
    {
        const response = await RequestHelper.post('/auth/google_oauth2', {
            code: codeResponse.code
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response; // Gibt die Antwort zurück
    } 
    catch (error) 
    {
        console.error('Error during Google login:', error);
        throw error; // Gibt den Fehler weiter
    }
}

export async function call_logout(): Promise<void> 
{
    try
    {
        const response = await RequestHelper.post("/auth/logout");
        return await response.data;
    }
    catch (error) {
        console.error('Error during Google logout:', error);
        throw error; // Gibt den Fehler weiter
    }
}

export async function getUserInformation(): Promise<any> 
{
    try
    {
        const response = await RequestHelper.get("/auth/getUserInformation");
        return await response.data;
    }
    catch (error) {
        console.error('Error trying to get the UserInformation:', error);
        throw error; // Gibt den Fehler weiter
    }
}