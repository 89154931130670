import React, { useState, useEffect } from 'react';
import {
    Chart as ChartJS,
    LineElement,
    LineController,
    PointElement,
    LinearScale,
    CategoryScale,
    Tooltip,
    Legend,
    TooltipItem,
    TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { ReactChart } from 'chartjs-react';
import { json } from 'stream/consumers';

// ChartJS-Controller registrieren
ChartJS.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend, TimeScale);

// Typen für die Props definieren
interface NetDebtChartProps {
    jsonData: any;
}

// Chart-Optionen
const createChartOptions = (title?: string) => ({
    responsive: true,
    plugins: {
        legend: {
            display: false, // Keine separate Legende für Linien
        },
        tooltip: {
            callbacks: {
                label: (tooltipItem: TooltipItem<'line'>) => {
                    const value = tooltipItem.raw || 0;
                    return `${title || 'Value'}: ${value}`;
                },
            },
        },
    },
    scales: {
        x: {
            type: 'category' as const, // Typ konkret definieren
            title: {
                display: true,
                text: 'Labels', // X-Achsenbeschriftung
            },
        },
        y: {
            type: 'linear' as const, // Typ konkret definieren
            title: {
                display: true,
                text: 'Values', // Y-Achsenbeschriftung
            },
            beginAtZero: true, // Y-Achse bei 0 starten
        },
    },
});

const updateJson = (data: any) => {
    const updated = {
        ...data.picture, // Kopiere die bestehenden Konfigurationen
        options: {
            ...data.picture.options,
            responsive: true,
            plugins: {
                ...data.picture.options.plugins,
                tooltip: {
                    ...data.picture.options.plugins.tooltip,
                    callbacks: {
                        label: (tooltipItem: TooltipItem<'line'>) => {
                            const value = tooltipItem.raw || 0;
                            return `${tooltipItem.dataset.label || 'Value'}: ${value}`;
                        },
                    },
                },
            },
        },
    };

    console.log(updated)

    return updated; // Rückgabe des aktualisierten Objekts
};



// Hauptkomponente für das Line Diagramm
const NetDebtChart: React.FC<NetDebtChartProps> = ({ jsonData }) => {
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        // Hier übernehmen wir jsonData und führen updateJson darauf aus
        if (jsonData) {
            setData(updateJson(jsonData)); // updateJson sollte auf jsonData angewendet werden
        }
    }, [jsonData]); // Nur wenn jsonData sich ändert

    return (
        data ? (
            <ReactChart
                type="line"
                data={data.data}  // Achte darauf, dass 'picture' existiert
                options={data.options}
            />
        ) : (
            <div>Loading...</div>  // Wenn die Daten noch nicht geladen sind
        )
    );
};

export default NetDebtChart;
