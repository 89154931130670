import React, { useEffect, useState } from 'react';
import "./Immobilien.css"

import FolderView from '../../components/folderview/FolderView';

import { useUser } from '../../context/Usercontext';

import RequestHelper from '../../components/requesthelper/RequestHelper';

import DropdownMenu, { DropdownProps } from '../../components/dropdownmenu/DropdownMenu';

import useLoadingLocal from '../../components/loading/hook/LoadingLocal';
import LoadingKompass from '../../components/loading/kompass/LoadingKompass';

import { useAblageSelection } from '../../context/AblageSelectioncontext';

import { toastEventEmitter } from '../../components/toast/ToastEmitter';

const Immobilien: React.FC = () => {
  const { user } = useUser();
  const [folderStack, setFolderStack] = useState<string[]>([]);

  const { isAblageSelected, getAblageSelected, setSelectedAblage } = useAblageSelection();

  const [isLoading, startLoading, stopLoading] = useLoadingLocal();

  const fetchStartFolderId = (selectedOption: { id: string; name: string }) => {
    startLoading();
    RequestHelper.post('/immobilien/getFolderId', selectedOption)
      .then(response => {
        const data = response.data;
        setFolderStack([data.startFolderId]);
      })
      .catch(error => {
        toastEventEmitter.addToast("Konnte das Verzeichnis des Users nicht anfragen", 3000, 'error')
      })
      .finally(() => {
        stopLoading();
      });
  };

  useEffect(() => {
    const ablage_selected = isAblageSelected();
    if (ablage_selected) {
      const ablage = getAblageSelected();
      if (ablage !== null) {
        fetchStartFolderId(ablage); // Hier übergibst du nur ablage, wenn es nicht null ist
      }
    }
  }, []);


  return (
    <div className='immobilien-container'>
      <h1>Immobilien</h1>
      <LoadingKompass isLoading={isLoading} />
      {/* {
        isAblageSelected() ? (
          <FolderView folderStack={folderStack} setFolderStack={setFolderStack} />
        ) : (
          <div>Wählen sie eine Ablage im Dashboard aus</div>
        )
      } */}

      <div className='immobilien-haus-auswahl'>
        <div className='immobilien-haus'>
          <img src="/logo/test.webp"></img>
          <div className="immobilien-haus-text-container">
            <p>3011 - 24259 Westensee, Dufenkamp 1</p>
          </div>
        </div>

        <div className='immobilien-haus'>
          <img src="/logo/test.webp"></img>
          <div className="immobilien-haus-text-container">
            <p>3011 - 24259 Westensee, Dufenkamp 1</p>
          </div>
        </div>

        <div className='immobilien-haus'>
          <img src="/logo/test.webp"></img>
          <div className="immobilien-haus-text-container">
            <p>3011 - 24259 Westensee, Dufenkamp 1</p>
          </div>
        </div>

        <div className='immobilien-haus'>
          <img src="/logo/test.webp"></img>
          <div className="immobilien-haus-text-container">
            <p>3011 - 24259 Westensee, Dufenkamp 1</p>
          </div>
        </div>

        <div className='immobilien-haus'>
          <img src="/logo/test.webp"></img>
          <div className="immobilien-haus-text-container">
            <p>3011 - 24259 Westensee, Dufenkamp 1</p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Immobilien;
