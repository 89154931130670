import React, {useEffect} from 'react';

import "./TopbarToggle.css"

import { useSideTopBar } from '../../../context/SideTopBarcontext';

import { setTopBarDynamicHeight } from '../Topbar';

const TopbarToggle: React.FC = () => {
    const { isTopbarExpanded, setIsTopbarExpanded } = useSideTopBar();

    return (
        <button onClick={() => {
            setIsTopbarExpanded(!isTopbarExpanded);
            setTopBarDynamicHeight();
        }} className={`topbar-toggle-button ${isTopbarExpanded ? 'expanded' : 'collapsed'}`}>
            {!isTopbarExpanded && <img src='/icons/burger-menu.svg' alt='ausklappen' />}
            {isTopbarExpanded && <img src='/icons/close.svg' alt='einklappen' />}
        </button>
    );
};

export default TopbarToggle;