import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';

/* Sites */
import Dashboard from './sites/dashboard/Dashboard';
import Immobilien from './sites/immobilien/Immobilien';
import Upload from './sites/upload/Upload';
import Signin from './sites/login/Signin';
import Vermoegensbilanz from './sites/vermoegensbilanz/Vermoegensbilanz';
import UserInitLoader from './sites/userinit/UserInitLoader';
import Adminpanel from './sites/admin/Adminpanel';

/* Context */
import { UserProvider } from './context/Usercontext';
import { useDevice } from './context/Devicecontext';
import { LoadingProvider } from './context/LoadingProvider';
import ToastProvider from './components/toast/ToastProvider';
import { useSideTopBar } from './context/SideTopBarcontext';
import { AblageSelectionProvider } from './context/AblageSelectioncontext';

/* Custom */
import Sidebar from './components/SideBar/Sidebar';
import Topbar from './components/TopBar/Topbar';


const App: React.FC = () => {
  const { isMobile } = useDevice();
  const { isSidebarExpanded, isTopbarExpanded } = useSideTopBar();

  return (
    <UserProvider>
      <LoadingProvider>
        <ToastProvider>
          <AblageSelectionProvider>
            <Router>
              <div className={`app-container ${isMobile ? 'mobile' : 'desktop'}`}>
                {!isMobile && <Sidebar/>}
                {isMobile && <Topbar/>}
                {/* Main content area */}
                <div className='main-content' id='main-content'>
                  <div className={`main-content-${isMobile ? (isTopbarExpanded ? 'topbarexpanded' : 'topbarcollapsed') : (isSidebarExpanded ? 'sidebarexpanded' : 'sidebarcollapsed')}`}>
                    <div className='main-login-header'>

                    </div>
                    <Routes>
                      <Route path="/adminpanel" element={<Adminpanel />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/immobilien" element={<Immobilien />} />
                      <Route path="/upload" element={<Upload />} />
                      <Route path="/vermoegensbilanz" element={<Vermoegensbilanz />} />
                      <Route path="/signin" element={<Signin />} />
                      <Route path="/userinitloader" element={<UserInitLoader />} />
                      <Route path="*" element={<Navigate to="/signin" />} />
                    </Routes>
                  </div>
                </div>
              </div>
            </Router>
          </AblageSelectionProvider>
        </ToastProvider>
      </LoadingProvider>
    </UserProvider>
  );
};

export default App;
