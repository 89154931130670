import { GoogleOAuthProvider } from "@react-oauth/google";
import "./Signin.css"
import React from 'react';

import Auth from "../../components/auth/Auth";

import { useUser } from "../../context/Usercontext";

const google_client_id: string = process.env.REACT_APP_GOOGLE_CLIENT_ID || "No Value configured";

const Signin: React.FC = () => {
  const { user, login, logout } = useUser();

  return (
    <div className='signin-container'>
      <GoogleOAuthProvider clientId={google_client_id} onScriptLoadError={() => console.error("Google OAuth Skript konnte nicht geladen werden")}>
        Hier können Sie sich einloggen oder ausloggen.
        <br></br>
        {user ? 
        <button className="google-login-btn"
            onClick={logout}>Logout
          </button> 
          : 
          <Auth/>
        }
      </GoogleOAuthProvider>
    </div>
  );
};

export default Signin;
