import React, {useEffect, useContext } from 'react';

import './Sidebar.css';

import SidebarLink from './button/SidebarLink';
import SidebarToggle from './button/SidebarToggle';

import { useUser } from '../../context/Usercontext';
import { useSideTopBar } from '../../context/SideTopBarcontext';

import { useNavigate } from 'react-router-dom';

const Sidebar: React.FC = () => {
    const { user } = useUser();
    const {isSidebarExpanded} = useSideTopBar();
    const navigate = useNavigate();

    const handleImageClick = () => {
        navigate('/dashboard');
    };
    
    return (
        <div className={`sidebar ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
            <div className="sidebar-logo-container">
                <div className={`sidebar-logo ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
                    {isSidebarExpanded && <img src="/logo/LogoGrossHell-v25-1.svg" alt='Kompaso' onClick={handleImageClick}></img>}
                    {!isSidebarExpanded && <img src="/logo/LogoKleinHell-v25-1.svg" alt='Kmps' onClick={handleImageClick}></img>}
                </div>
            </div>

            <div className={`sidebar-toggle-container ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
                <SidebarToggle/>
            </div>

            <div className='sidebar-content'>
                {user && isSidebarExpanded && 
                    <div className='sidebar-userinformation'>
                        <img src={`data:image/png;base64,${user.picture}`} alt=""/>
                        <b>{user?.name}</b>
                    </div>
                }

                {user &&
                <ul className="sidebar-menu">
                     <li>
                        <SidebarLink to="/dashboard" iconClass="icon icon-dashboard" label="Dashboard"/>
                    </li>
                    <div className='sidebar-line'></div>
                    <li>
                        <SidebarLink to="/upload" iconClass="icon icon-upload" label="Upload"/>
                    </li>
                    <div className='sidebar-line'></div>
                    <li>
                        <SidebarLink to="/immobilien" iconClass="icon icon-immobilien" label="A. Immobilien"/>
                    </li>
                    <li>
                        <SidebarLink to="/depots" iconClass="icon" label="B. Depots"/>
                    </li>
                    <li>
                        <SidebarLink to="/kredite" iconClass="icon" label="F. Kredite"/>
                    </li>
                    <div className='sidebar-line'></div>
                </ul>
                }
            </div>

            {/* Bereich für Links am unteren Rand */}
            {isSidebarExpanded && 
            <div className="sidebar-bottom">
                {user && <SidebarLink to="/vermoegensbilanz" iconClass="icon icon-settings" label="Admin" />}
                {user && <SidebarLink to="/settings" iconClass="icon icon-settings" label="Settings" />}
                <SidebarLink to="/signin" iconClass="icon icon-signin" label={user ? "Sign out" : "Sign in"} />
                {/* {user && <SidebarLink to="/adminpanel" iconClass="icon icon-dashboard" label="Adminpanel"/>} */}
                <div className='sidebar-datenschutz-impressum'>
                    <text>Datenschutz :</text>
                    <text>Impressum</text>
                </div>
            </div>
            }

            {/* {isSidebarExpanded && 
            <div className='sidebar-bottom'>
                <p>KOMPASO GmbH</p>
            </div>
            } */}

        </div>
    );
};

export default Sidebar;
