import React, { useRef, useEffect, useState } from 'react';
import './UserInitLoader.css';

import RequestHelper from '../../components/requesthelper/RequestHelper';

import { useUser, User } from '../../context/Usercontext';

import { getUserInformation } from '../../components/auth/AuthHelper';

const UserInitLoader: React.FC = () => {
    const { user, login, logout } = useUser();

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const response = await RequestHelper.post('/finishedInit'); // Replace with your API endpoint
                if (response.status !== 200) {
                    console.log("nicht fertig");
                } else {
                    console.log("fertig");
                    const data = await getUserInformation(); // Hier wird await verwendet
                    login(data);
                }
            } catch (error) {
                console.log("ERROR", error);
            }
        }, 2000);

        return () => clearInterval(interval); // Cleanup-Funktion, um das Intervall zu entfernen, wenn die Komponente unmountet
    }, []);


    return (
        <div className='userinit-container'>
            Wir erstellen ihren Nutzeraccount.
            Sie werden sofort weitergeleitet, wenn die Erstellung abgeschlossen ist
            *Loading Bar*
        </div>
    );
}

export default UserInitLoader;