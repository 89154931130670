import axios, { AxiosInstance, AxiosError, InternalAxiosRequestConfig, AxiosRequestConfig } from 'axios';
import { call_logout } from '../auth/AuthHelper';

// Deine Axios-Instanz
const RequestHelper: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_REQUESTHANDLER_BASE_URL, // Basis-URL des Flask-Backends
    withCredentials: true, // Entspricht `credentials: 'same-origin'` bei fetch
});

// Interceptor für Anfragen - CSRF-Token hinzufügen
RequestHelper.interceptors.request.use(
    (config) => {
        // Füge hier weitere Header hinzu, wenn nötig
        return config;
    },
    (error) => Promise.reject(error)
);


// Interceptor für Antworten - Fehlerbehandlung bei 401
RequestHelper.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error: AxiosError) => {
        // const { logout } = useUser();        // -> HAT DAMALS EINEN FELHER AUSGELÖST -> WIE REAGIER ICH IN ZUKUNFT DARAUF?
        const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };

        if (error.response?.status === 401 && !originalRequest._retry) {
            // Markiere die Anfrage als einmalig wiederholt
            originalRequest._retry = true;

            console.warn('Token abgelaufen. Erneut versuchen, das Token zu erneuern...');
            try {
                // Rufe eine Funktion auf, um das Token zu erneuern (Implementierung erforderlich)
                const response_ = await axios.post('/app/token/refresh', {}, 
                    { 
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json' // Setzt Content-Type Header
                        } 
                    }
                );

                if (response_.status != 200){
                    // Token konnte nicht erneut werden -> User muss sich komplett neu anmelden
                    // logout();
                    return Promise.reject("Token komplett abgelaufen.");
                }

                // Wiederhole die ursprüngliche Anfrage mit dem neuen Token
                return RequestHelper(originalRequest);
            } catch (refreshError) {
                console.error('Token-Erneuerung fehlgeschlagen', refreshError);
                
                return Promise.reject(refreshError);
            }
        }
        else if(error.response?.status === 410) // Google Access Token ist abgelaufen / Normaler Access Token ist abgelaufen
        {
            //call_logout();
        }
        else if(error.response?.status === 500) // Prüfe auf 500-Fehler
        {
            console.error('Internal Server Error (500). Anfrage wird nicht wiederholt.');
            // Hier kannst du eine spezifische Fehlerbehandlung vornehmen
            return Promise.reject(error); // Gibt den Fehler zurück, ohne die Anfrage zu wiederholen
        }
        return Promise.reject(error);
    }
);

export default RequestHelper;
