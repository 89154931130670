/* Dieses Element stellt eine Auswahlzeile oben an der Seite dar */
/* Ist ein Ersatz für die Sidebar, da diese auf mobilen Geräten schlecht aussehen würde */

import React, { useEffect } from 'react';
import './Topbar.css';

import TopbarToggle from './button/TopbarToggle';
import TopbarLink from './button/TopbarLink';

import { useUser } from '../../context/Usercontext';
import { useSideTopBar } from '../../context/SideTopBarcontext';

import { useNavigate } from 'react-router-dom';

import { useDevice } from '../../context/Devicecontext';

export function setTopBarDynamicHeight(): void {
    const containers = document.getElementsByClassName('main-content');
    const headers_exp = document.getElementsByClassName('topbar expanded'); // Get all elements with class 'header'
    const headers_col = document.getElementsByClassName('topbar collapsed'); // Optional footer class

    if (containers.length > 0 && (headers_exp.length > 0 || headers_col.length > 0)) {
        const container = containers[0] as HTMLElement; // Get the first container
        const header_exp = headers_exp.length > 0 ? (headers_exp[0] as HTMLElement) : null;
        const header_col = headers_col.length > 0 ? (headers_col[0] as HTMLElement) : null;

        requestAnimationFrame(() => {
            const headerHeight = header_exp ? header_exp.offsetHeight : (header_col ? header_col.offsetHeight : 0); // Dynamically get the height

            // Adjust the height of the container
            (container as HTMLElement).style.top = `${headerHeight}px`;
        });
    }
}

const Topbar: React.FC = () => {
    const { user } = useUser();
    const { isTopbarExpanded } = useSideTopBar();
    const { isMobile } = useDevice();
    const navigate = useNavigate();

    const handleImageClick = () => {
        navigate('/dashboard');
    };

    useEffect(() => {
        if (isMobile) {
            setTopBarDynamicHeight();
        }
        else {
            const containers = document.getElementById('main-content');
            (containers as HTMLElement).style.top = `0px`;
        }
    }, [isMobile]);

    return (
        <div className={`topbar ${isTopbarExpanded ? 'expanded' : 'collapsed'}`}>
            <div className='topbar-header'>
                <div className='topbar-logo'>
                    <img src="/logo/LogoGrossHell-v25-1.svg" alt='Kompaso' onClick={handleImageClick} />
                </div>
                <div className={`topbar-toggle-container ${isTopbarExpanded ? 'expanded' : 'collapsed'}`}>
                    <TopbarToggle />
                </div>
            </div>

            {isTopbarExpanded && <div className='topbar-content'>
                <ul className="menu">
                    {user && <li>
                        <TopbarLink to="/dashboard" iconClass="" label="Dashboard" />
                    </li>}
                    {user && <li>
                        <TopbarLink to="/upload" iconClass="icon icon-upload" label="Upload" />
                    </li>}
                    {user && <li>
                        <TopbarLink to="/immobilien" iconClass="icon icon-immobilien" label="A. Immobilien"/>
                    </li>}
                    {user && <li>
                        <TopbarLink to="/depots" iconClass="icon icon-immobilien" label="B. Depots"/>
                    </li>}
                    {user && <li>
                        <TopbarLink to="/kredite" iconClass="icon icon-immobilien" label="F. Kredite"/>
                    </li>}
                    <li>
                        <TopbarLink to="/signin" iconClass="icon icon-signin" label={user ? "Sign out" : "Sign in"} />
                    </li>
                    {user && <li>
                        <TopbarLink to="/vermoegensbilanz" iconClass="icon" label="Vermögensbilanz" />
                    </li>}
                    {user && <li>
                        <TopbarLink to="/adminpanel" iconClass="icon icon-dashboard" label="Adminpanel"/>
                    </li>}
                </ul>
            </div>}
        </div>
    );
};

export default Topbar;
