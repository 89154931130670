import React, { createContext, useContext, useEffect, useState } from 'react';

import { setTopBarDynamicHeight } from '../components/TopBar/Topbar';

// Typen für das Context-Interface
interface DeviceContextType {
  isMobile: boolean;
}

// Erstellen des Contexts mit einem Standardwert
const DeviceContext = createContext<DeviceContextType | undefined>(undefined);

// Mindest-Breite für ein Desktop-Gerät, kann angepasst werden
const MOBILE_WIDTH = 768;

// DeviceProvider Komponente
export const DeviceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < MOBILE_WIDTH);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_WIDTH);
      setTopBarDynamicHeight();
    };

    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <DeviceContext.Provider value={{ isMobile }}>
      {children}
    </DeviceContext.Provider>
  );
};

// Custom Hook, um den Context einfacher verwenden zu können
export const useDevice = (): DeviceContextType => {
  const context = useContext(DeviceContext);
  if (context === undefined) {
    throw new Error('useDevice muss innerhalb eines DeviceProviders verwendet werden');
  }
  return context;
};
