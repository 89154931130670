import React, { createContext, useContext, useEffect, useState } from 'react';

// Typen für das Context-Interface
interface AblageSelectionContextType {
    isAblageSelected:() => boolean;
    getAblageSelected:() => { id: string; name: string } | null;
    setSelectedAblage:(user: { id: string; name: string }) => void;
}

// Erstellen des Contexts mit einem Standardwert
const AblageSelectionContext = createContext<AblageSelectionContextType | undefined>(undefined);

export const AblageSelectionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [ablage, setAblage] = useState<{ id: string; name: string } | null>(null);

    const setSelectedAblage = (ablage: { id: string; name: string }) => {
        setAblage(ablage);
    }

    const isAblageSelected = (): boolean => {
        return ablage !== null; 
    }

    const getAblageSelected = (): { id: string; name: string } | null => {
        return ablage;  // Rückgabe von `ablage`, kann auch null sein
    }
    
    return (
        <AblageSelectionContext.Provider value={{ isAblageSelected, getAblageSelected, setSelectedAblage }}>
            {children}
        </AblageSelectionContext.Provider>
    );
};

// Custom Hook, um den Context einfacher verwenden zu können
export const useAblageSelection = (): AblageSelectionContextType => {
    const context = useContext(AblageSelectionContext);
    if (context === undefined) {
        throw new Error('AblageSelectionContext muss innerhalb eines AblageSelectionProviders verwendet werden');
    }
    return context;
};
