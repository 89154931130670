import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

export interface DropdownProps {
    options: { name: string; id: string }[];
    selectedOption: { name: string; id: string } | null;
    onOptionSelected: (selectedOption: { name: string; id: string }) => void;
    spaceholder: string;
    maxHeight?: number; // Optional prop for setting max height
}

const DropdownMenu: React.FC<DropdownProps> = ({ 
    options, 
    selectedOption, 
    onOptionSelected, 
    spaceholder,
    maxHeight = 200, // Default max height (adjust as needed)
}) => {
    const handleSelect = (eventKey: string | null) => {
        if (eventKey) {
            const selectedOption = options.find((option) => option.id == eventKey);
            if (selectedOption) {
                onOptionSelected(selectedOption);
            }
        }
    };

    return (
        <DropdownButton
            id="dropdown-basic-button"
            title={selectedOption?.name || spaceholder}
            onSelect={handleSelect}
            style={{maxHeight}}
        >
            {options.map((option) => (
                <Dropdown.Item key={option.id} eventKey={option.id}>
                    {option.name}
                </Dropdown.Item>
            ))}
        </DropdownButton>
    );
};

export default DropdownMenu;