import React, { useEffect, useState } from 'react';
import "./Adminpanel.css"

const Adminpanel: React.FC = () => {
 
  return (
    <div className='adminpanel-container'>
      <h1>Adminpanel</h1>
    </div>
  );
};

export default Adminpanel;
