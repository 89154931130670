import React, { useRef, useEffect, useState } from 'react';
import './Dashboard.css';

import { toastEventEmitter } from '../../components/toast/ToastEmitter';

import DoughnutChart from '../../components/charts/doughnut/DoughnutChart';
import NetDebtChart from '../../components/charts/line/NetDebtChart';

import RequestHelper from '../../components/requesthelper/RequestHelper';

import LoadingKompass from '../../components/loading/kompass/LoadingKompass';

import DropdownMenu, { DropdownProps } from '../../components/dropdownmenu/DropdownMenu';

import { AblageSelectionProvider, useAblageSelection } from '../../context/AblageSelectioncontext';
import useLoadingLocal from '../../components/loading/hook/LoadingLocal';

const Dashboard: React.FC = () => {
    const [ablage, setAblage] = useState<DropdownProps["options"]>([]);
    
    const [jsonData, setJsonData] = useState<any>(null);

    const { isAblageSelected, getAblageSelected, setSelectedAblage } = useAblageSelection();

    const [isLoading, startLoading, stopLoading] = useLoadingLocal();

    const fetchNetDebtChartData = (selectedOption: { id: string; name: string }) => {
        RequestHelper.post('/vermoegenstabelle/frontend/getNetDebtData', selectedOption)
            .then(response => {
                setJsonData(response.data);
            })
            .catch(error => {
                toastEventEmitter.addToast("Konnte ihre Vermögensdaten nicht ermitteln", 3000, 'error')
            })
            .finally(() => {

            });
    };

    // Wählt direkt den ersten User aus, falls es nur einen gibt
    useEffect(() => {
        if (ablage.length == 1) {
            handleAblageSelected(ablage[0]);
        }
    }, [ablage, setSelectedAblage]);

    const handleAblageSelected = (selectedOption: { id: string; name: string }) => {
        setSelectedAblage(selectedOption); // Update state in the parent component
        console.log("Selected Ablage:", selectedOption); // Do something with the option
        setJsonData(null);

        toastEventEmitter.addToast(selectedOption.name + " ausgewählt", 3000, 'success')

        fetchNetDebtChartData(selectedOption);
    };

    useEffect(() => {
        startLoading();
        RequestHelper.post('/sharedDrivesList') // Replace with your API endpoint
            .then(response => {
                const data = response.data;
                console.log(data);
                setAblage(
                    data.map((item: { id: string; name: string }) => ({
                        name: item.name, // Use the appropriate property for display
                        id: item.id, // Use the appropriate property for value
                    }))
                );
            })
            .catch(error => {
                console.error('Error fetching shared drives', error);
                toastEventEmitter.addToast("Konnte keine geteilten Ablagen auslesen", 3000, 'error')
            })
            .finally(() => {
                stopLoading();
            });
    }, []);

    return (
        <div className='dashboard-container'>
            <h1>Dashboard</h1>
            <LoadingKompass isLoading={isLoading} />
            <div className='dashboard-driveAuswahl'>
                <DropdownMenu
                    options={ablage}
                    selectedOption={getAblageSelected()}
                    onOptionSelected={handleAblageSelected}
                    spaceholder='Wählen Sie einen Nutzer'
                />
            </div>
            <div className='test-chartjs'>
                {/* <DoughnutChart
                    labels={labels}
                    data={data}
                    backgroundColors={backgroundColors}
                    borderColors={borderColors}
                    title="Test-Diagramm"
                /> */}

                {jsonData ? 
                    <NetDebtChart
                        jsonData={jsonData}
                    /> : ""
                }
            </div>
        </div>
    );
};

export default Dashboard;